<template>
  <div style="width: 100%; height: 100%">
    <van-nav-bar title="" @click-left="onClickLeft" left-arrow />
    <!-- <van-button type="info" onClick="bimViewPointNew.hiddenViewPoint()">视点保存</van-button> -->
    <!-- <canvas id="snapshot" hidden></canvas> -->
    <!-- <img id="test" /> -->
    <div id="div_viewpoint" style="top: 100%"></div>

    <!-- <div id="div_viewpoint" style="left:0;top:50px" class="m-right-sidebar">
      <h2 class="sdbcName">视点保存</h2>
      <div
        class="btnClose close_bt_p"
        onclick="bimViewPointNew.hiddenViewPoint();"
      >
        ×
      </div>
      <div class="navBox">
        <div id="search_viewpoint" class="search">
          <b class="imgsearch"></b>
          <button
            id="vp-imgsearch"
            class="btnSearch"
            onclick="bimViewPointNew.filterNodes()"
          >
            <img
              width="20px"
              class="pos-re-left4"
              src="../../assets/img/搜索 (2).png"
            />
          </button>
          <input
            type="text"
            id="vp-searchtxt"
            value="请输入搜索内容..."
            onfocus="if(value=='请输入搜索内容...')  {value=''}"
            onblur="if (value=='') {value='请输入搜索内容...'}"
            onkeypress="bimViewPointNew.viewpoint_keydown(event)"
            name="name"
          />
          <b class="clo1" id="vp-close" onclick="bimViewPointNew.closeShow()"
            >×</b
          >
        </div>
      </div>
      <div class="body_viewpoint">
        <div
          id="viewpointUser"
          style="width: 100%; max-height: 10%; overflow: auto"
        ></div>
        <ul id="treeDemo_viewpoint" class="treeDemo_viewpoint ztree"></ul>
      </div>
      <div class="w-h-bg-cor">
        <el-button
          type="primary"
          id="vp-btn-mark"
          data-placement="bottom"
          title="涂鸦"
          size="small"
          onclick="bimViewPointNew.showMarkTool()"
          >涂鸦</el-button
        >

        <el-button
          type="primary"
          id="vp-btn-save"
          data-placement="bottom"
          size="small"
          title="保存"
          onclick="bimViewPointNew.onSaveLayer()"
          >保存</el-button
        >

        <el-button
          type="primary"
          id="vp-btn-save"
          data-placement="bottom"
          size="small"
          title="分享管理"
          >分享管理</el-button
        >
      </div>
      <div id="rMenu_viewpoint" class="rMenu pos-fixed">
        <ul id="rShowMenu_viewpoint">
          <li
            id="vp_m_add"
            class="dis-lis-item"
            onclick="bimViewPointNew.addTreeNode()"
          >
            新建视点目录
          </li>
          <li
            id="vp_m_del"
            class="dis-lis-item"
            onclick="bimViewPointNew.removeTreeNode()"
          >
            删除
          </li>
          <li
            id="vp_m_ren"
            class="dis-lis-item"
            onclick="bimViewPointNew.renameTreeNode()"
          >
            重命名
          </li>
          <li
            id="vp_m_visible"
            class="dis-lis-item"
            onclick="bimViewPointNew.setVisible()"
          >
            可见
          </li>
          <li
            id="vp_m_share"
            onclick="bimViewPointNew.shareTreeNode()"
            class="dis-lis-item"
          >
            分享视点
          </li>
        </ul>
      </div>
    </div> -->

    <div id="MyViewerDiv"></div>
    <van-popup
      position="bottom"
      v-model="isShow"
      round
      :style="{ height: '60%' }"
    >
      <div style="padding: 10px; width: calc(100vh-20px)">
        <span class="decoration" style="font-size: 16px">视点查看</span>
        <van-search
          v-model="sousuo"
          @input="sousuoInput"
          placeholder="请输入搜索关键词"
        />
        <div
          style="width: 100%; justify-content: flex-start; padding: 10px 0"
          class="flex"
        >
          <div
            v-for="(item, index) in UserList"
            @click="showUserPoint(item)"
            :key="index"
            style="margin-right: 10px"
          >
            <div v-if="item.user13" class="userImg">
              <img :src="$store.state.baseUrl + item.user13" alt="" />
            </div>
            <div class="userImg" v-if="!item.user13">
              {{ item.user04.slice(item.user04.length - 1) }}
            </div>
          </div>
        </div>
        <div v-if="PointList.length > 0" class="popDiv">
          <div
            class="flexLR pointDiv"
            v-for="(item, index) in PointList"
            :key="index"
            @click="showPoint(item)"
          >
            <div>{{ item.vipo02 }}</div>
          </div>
        </div>
        <div
          v-else
          style="color: #0a9efc; margin-top: 100px; text-align: center"
        >
          <img src="../../assets/mobileImg/wu.png" width="60" alt="" />
          <div>暂无数据</div>
        </div>
        <van-button
          @click="
            $router.push({ path: '/administration', query: { id: modelid } })
          "
          type="info"
          style="width: 100%; margin-top: 10px"
          icon="flag-o"
          >分享管理</van-button
        >
      </div>
    </van-popup>
    <van-button
      type="info"
      @click="showTool"
      v-if="btnShow"
      style="position: absolute; top: 45px; left: 0; z-index: 999"
      size="small"
      >退出</van-button
    >
  </div>
</template>

<script>
import {
  FindWorkPlaneModel,
  GetViewPointUser,
  GetViewPoint,
  GetModelInfo,
} from "@/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      model: {},
      isShow: false,
      sousuo: "",
      modelid: "",
      uid: "",
      PointList: [],
      UserList: [],
      markup: null,
      btnShow: false,
    };
  },
  computed: {
    ...mapGetters(["qid", "pid"]),
  },
  methods: {
    sousuoInput(el) {
      if (el) {
        this.PointList = this.PointList.filter(
          (v) => v.vipo02.indexOf(el) != -1
        );
      } else {
        this.getPointData(this.uid);
      }
    },
    showTool() {
      this.markup.leaveEditMode();
      this.markup.hide();
      EaBIM.view.unloadExtension("Autodesk.Viewing.MarkupsCore");
      this.markup = null;
      this.btnShow = false;
    },
    showUserPoint(item) {
      this.getPointData(item.user01);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 模型初始化显示
    showModel(data) {
      data.enterType = "yydh";
      EaBIM.localServerModels(data);
    },
    showPoint(item) {
      var _this = this;
      var json = item.vipo06.replace(/'/g, '"');
      var jsonObject = JSON.parse(json);
      if (EaBIM.view.restoreState(jsonObject)) {
        EaBIM.view
          .loadExtension("Autodesk.Viewing.MarkupsCore")
          .then((markupM) => {
            _this.markup = markupM;
            _this.markup.load();
            // markupM.enterEditMode();
            var markup = item.vipo07;
            setTimeout(() => {
              _this.markup.show();
              if (markup && markup.replace(/(^\s*)|(\s*$)/g, "").length > 0) {
                var markupList = markup.split("@#@");
                if (markupList != null && markupList.length > 0) {
                  for (var i = 0, l = markupList.length; i < l; i++) {
                    var singleMarkup = markupList[i].split("@BIM@");
                    _this.markup.loadMarkups(
                      singleMarkup[1].replace(/'/g, '"'),
                      singleMarkup[0]
                    );
                  }
                }
              }
            }, 500);
          });
      }
      this.isShow = false;
      this.btnShow = true;
    },
    // 模型详情
    async GetModelInfo(id) {
      let res = await FindWorkPlaneModel({ id });
      this.showModel(res.Data);
      this.modelid = res.Data.modelId;
      this.getPointData();
      this.getPointUser();
    },
    async getPointData(uid) {
      let res = await GetViewPoint({
        modelid: this.modelid,
        modeltype: 1,
        selectId: "",
        userId: uid || "",
      });
      this.uid = uid;
      this.PointList = res.Data.filter((v) => v.vipo10 == 0);
    },
    async getPointUser() {
      let res = await GetViewPointUser({
        params: {
          id: this.modelid,
        },
      });
      this.UserList = res.Data;
    },
    viewers(url) {
      var Params = this.locationParams,
        _this = this;
      //初始化
      var config = {
        extensions: [
          "Autodesk.Viewing.ZoomWindow",
          // "Autodesk.Viewing.Collaboration",
        ],
        disabledExtensions: {
          measure: false,
          section: false,
        },
        memory: {
          limit: 32 * 1024, //32 GB
        },
      };
      var myViewerDiv = document.getElementById("MyViewerDiv");
      _this.viewer = new Autodesk.Viewing.Private.GuiViewer3D(
        myViewerDiv,
        config
      );
      var options = {
        document: url,
        language: "zh-HANS",
        env: "Local",
        offline: "true",
        useADP: false,
      };
      Autodesk.Viewing.Initializer(options, function onInitialized() {
        _this.viewer.start();
        _this.viewer.loadModel(options.document, {
          isAEC: true,
        });
        _this.viewer.setTheme("light-theme");
        _this.viewer.addEventListener(
          Autodesk.Viewing.TEXTURES_LOADED_EVENT,
          function (event) {
            //选择集
            var json = Params.state.replace(/'/g, '"');
            var jsonObject = JSON.parse(json);
            _this.viewer.restoreState(jsonObject);
          }
        );
      });
    },
  },
  mounted() {
    let _this = this;
    function observe(el, options, callback) {
      var MutationObserver =
        window.MutationObserver ||
        window.WebKitMutationObserver ||
        window.MozMutationObserver;
      var observer = new MutationObserver(callback);
      observer.observe(el, options);
    }
    var text = document.getElementById("div_viewpoint");
    var options = {
      childList: true,
      subtree: true,
      attributes: true,
      attributeOldValue: true,
      characterData: true,
    };
    observe(text, options, (records, instance) => {
      records.map((record) => {
        _this.isShow = record.oldValue.includes("none");
      });
    });
    if (this.$route.query.modelId) {
      let params = {
        modelId: this.$route.query.modelId,
        mtype: "",
      };
      GetModelInfo(params).then((res) => {
        if (res.Code != 1) {
          this.$Toast(res.Message);
          return;
        }
        EaBIM.modelData.modelId = this.$route.query.modelId;
        this.viewers(this.$store.state.baseUrl + res.Data.mode09);
      });
    }
  },
  created() {
    if (this.$route.query.id) {
      this.GetModelInfo(this.$route.query.id);
    }
  },
};
</script>


<style lang="scss" scoped>
.popDiv {
  height: 540px;
  width: 100%;
  overflow: auto;
  padding: 5px;
}
#MyViewerDiv {
  height: 100%;
  width: 100%;
  position: absolute;
}
.markcircle {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 100px;
  left: 0;
  background-color: rgb(31, 147, 255);
  box-shadow: 0 0 6px 1px #fff;
}
.pointDiv {
  border-radius: 10px;
  min-height: 80px;
  padding: 0 40px;
  box-shadow: 0 0 8px 5px rgb(238, 238, 238);
  margin: 20px 0;
}

.marklabel {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(31, 147, 255);
  position: absolute;
  top: 65px;
  left: -56px;
  z-index: -1;
}

.markwire2 {
  position: absolute;
  top: -54px;
  left: 3px;
  width: 40px;
  height: 2px;
  box-shadow: 0 0 6px 1px #fff;
  z-index: 1;
  background-color: rgb(31, 147, 255);
  transform: rotate(-30deg);
}

.userImg {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgb(0, 89, 255);
  text-align: center;
  img {
    width: 80%;
    height: 80%;
  }
  line-height: 80px;
  color: white;
  font-size: 32px;
}

/deep/ #EaBIM_button_2D,
/deep/ #EaBIM_button_versions,
/deep/ #EaBIM_button_crashCheck,
/deep/ #EaBIM_button_filter,
/deep/ #EaBIM_button_selectWindow,
/deep/ #toolbar-fullscreenTool,
/deep/ #toolbar-modelStructureTool,
/deep/ #toolbar-explodeTool,
/deep/ #toolbar-levelsTool,
/deep/ #toolbar-measurementSubmenuTool {
  display: none;
}

.markwire {
  position: absolute;
  top: -23px;
  width: 44px;
  left: -17px;
  box-shadow: 0 0 6px 1px #fff;
  height: 2px;
  background-color: rgb(31, 147, 255);
  transform: rotate(-90deg);
}

.markframe {
  position: relative;
  box-sizing: border-box;
  /* font-size: 12px; */
  /* line-height: 1.5; */
  border-width: 2px;
  border-style: solid;
  border-color: rgb(31, 147, 255);
  background-color: rgb(238, 238, 238);
  opacity: 0.8;
  /* width: 160px;
  height: 40px; */
  width: 85px;
  height: 30px;
  left: 40px;
  top: -77px;
  cursor: pointer;
  /* overflow-x: hidden;
    overflow-y: auto; */
  overflow: hidden;
  border-radius: 5px;

  font-size: 16px;
  line-height: 25px;
  padding: 0px 2px;
  color: black;
}

.markframe img {
  height: 40px;
  width: 40px;
  display: block;
  float: left;
}
.markframe div {
  height: 100%;
  /* overflow: auto; */
  padding: 0px 2px;
  color: black;
}
.markframe div p {
  font-size: 16px;
  line-height: 40px;
}
</style>